
import * as React from "react";
import Head from 'next/head'
import Script from "next/script";
import { useSession } from "next-auth/client";

export const CustomHead = ({
  title = "Tweet Hunter 🏹",
  desc = "Use TweetHunter to find the best tweets on any topic and grow your Twitter account to new levels.",
  url = "https://app.tweethunter.io",
  // image="https://app.tweethunter.io/social.jpg",
  image = "",
  type = "website",
  disableProfitwell = false,
  includeCaptureEmail = false,
  noIndex = false,
}) => {

  const [session, loading] = useSession();

  if (!image)
    image = "https://ondemand.bannerbear.com/simpleurl/9MOpzJ843LWBnGWYvq/image/title/text/" + encodeURIComponent(title.replace(" | Tweet hunter", ""));

  return (
    <>
      <Head>
        <title>{title}</title>
        <link href="https://fonts.googleapis.com/css2?family=Rubik&display=swap" rel="stylesheet" />
        <link rel="canonical" href={url} />

        <meta name="description" content={desc} />
        {/* Twitter */}
        <meta name="twitter:card" content="summary" key="twcard" />
        <meta name="twitter:site" content={url} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />

        {
          (url?.includes("https://app.tweethunter.io") || noIndex) && (
            <meta name="robots" content="noindex" />
          )
        }

        {/* Open Graph */}
        <meta property="og:url" content={url} key="ogurl" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta property="og:image" content={image} key="ogimage" />
        <meta property="og:site_name" content={title} key="ogsitename" />
        <meta property="og:title" content={title} key="ogtitle" />
        <meta property="og:description" content={desc} key="ogdesc" />
        <meta property="og:type" content={type} />

        {/* {
          !disableProfitwell && session?.user?.data?.stripeId && (
            <script dangerouslySetInnerHTML={{ __html: `(function(){ if (!window.churnkey || !window.churnkey.created) { window.churnkey = { created: true }; const a = document.createElement('script'); a.src = 'https://assets.churnkey.co/js/app.js?appId=qsdeu5psd'; a.async = true; const b = document.getElementsByTagName('script')[0]; b.parentNode.insertBefore(a, b); } })();` }} />
          )
        } */}

        <Script strategy="lazyOnload" dangerouslySetInnerHTML={{
          __html: `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version="2.0";
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,"script",
"https://connect.facebook.net/en_US/fbevents.js");
fbq("init", "2991899637693143");
fbq("track", "PageView");` }} />

        {/* <Script strategy="lazyOnload" dangerouslySetInnerHTML={{ __html: `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date(); (function(){ var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0]; s1.async=true; s1.src='https://embed.tawk.to/60c71b1c65b7290ac635d2d2/1f84s3ppn'; s1.charset='UTF-8'; s1.setAttribute('crossorigin','*'); s0.parentNode.insertBefore(s1,s0); })();` }} /> */}

        {/* {
          includeCaptureEmail && (
            <Script strategy="lazyOnload" type="text/javascript" src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=VpHJDR"/>
          )
        } */}
      </Head>
    </>
  );
};
