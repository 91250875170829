import React, { useEffect } from "react";
import Link from "next/link";

import { 
  Box,
  Center, 
} from "@chakra-ui/layout";
import { useRouter } from "next/dist/client/router";
import { useSession } from "next-auth/client";
import { CustomHead } from "components/custom-head";
import PuffLoader from "react-spinners/PuffLoader";

const Index = (props) => {
  
  const [session, loading] = useSession();
  const router = useRouter();

  useEffect(() => {  
    if (typeof window !== "undefined" && !loading) {
      if (session?.user?.uid && session?.user?.data?.subscription?.isSubscribed) {
        router.push("/auto");
      }
      else if (session?.user?.uid) {
        router.push("/subscribe");
      }
      else {
        router.push("https://tweethunter.io" + window?.location?.search);
      }
    }
  },[session, loading]);

  // if (props.query) 
  //   console.log(props.query);

  let url = "https://tweethunter.io" + (props.query ? "?" + new URLSearchParams(props.query).toString() : "");     
  // console.log("url: " + url);

  return (
    <Box w="100%" h="100vh">
      {/* {
        typeof window !== 'undefined' && (
          <Iframe url={url}
            width="100%"
            height="100%"
            id="myId"
            className="home"
            position="relative"/>
        )
      } */}
      <CustomHead noIndex={true} />
      <Center position="absolute" top="50%" left="50%" transform="translateX(-50%)">
        <PuffLoader color={"hsl(203, 100%, 36%)"} loading={true} css="display: block; margin: 0 auto; border-color: red;"  size={50} />
      </Center>
    </Box>
  );
};

Index.getInitialProps = async ({query}) => {

  let result = {
    query: query,
  }

  return result;
}

export default Index;
